import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from "./manager/apiConfig.js";
import OrderMessage from './OrderMessage';
import { Button, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './css/CheckOrders.css';

const CheckOrders = () => {
    const [orders, setOrders] = useState([]);
    const [username, setUsername] = useState('');
    const [searchParams, setSearchParams] = useState({
        order_id: '',
        recipient_name: '',
        address: '',
        mobile: '',
        email: ''
    });
    const [activeTab, setActiveTab] = useState('待付款');
    const [orderCounts, setOrderCounts] = useState({
        待處理: 0,
        待回填後四碼: 0,
        待付款: 0,
        待出貨: 0,
        待收貨: 0,
        訂單已完成: 0,
        待換貨: 0 // 新增狀態
    });
    const [sortOrder, setSortOrder] = useState('desc');
    const [remittanceInfo, setRemittanceInfo] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [showDeliveredModal, setShowDeliveredModal] = useState(false);
    const [selectedDeliveredOrderId, setSelectedDeliveredOrderId] = useState(null);
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [picture, setPicture] = useState(null);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [discountData, setDiscountData] = useState({});
    const [shippingCostData, setShippingCostData] = useState([]);
    const [showRemittanceModal, setShowRemittanceModal] = useState(false);
    const [remittanceCode, setRemittanceCode] = useState(['', '', '', '']);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
            fetchOrders(storedUsername, searchParams, activeTab);
            fetchShippingCostData();
        }
    }, [activeTab, sortOrder]);

    useEffect(() => {
        if (activeTab === '待付款') {
            fetchRemittanceInfo();
        }
    }, [activeTab]);

    // 處理每個數字輸入框的變化
    const handleDigitChange = (index, value) => {
        // 確保只能輸入數字
        if (value !== '' && !/^\d$/.test(value)) return;

        const newCode = [...remittanceCode];
        newCode[index] = value;
        setRemittanceCode(newCode);

        // 自動跳到下一個輸入框
        if (value !== '' && index < 3) {
            const nextInput = document.getElementById(`digit-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    const handleRemittanceCodeSubmit = async (code) => {
        try {
            const response = await axios.post(`${baseURL}/cart/updateRemittanceCode/`, {
                order_id: selectedOrderId,
                remittance_code: code,
                payment_method: '匯款',  // 新增付款方式
                status: '待處理'
            });
            
            console.log('API Response:', response.data); // 添加調試日誌
    
            // 修改這裡的判斷邏輯，確保與後端返回格式匹配
            if (response.data && response.data.status === 1) {
                // 關閉 Modal 並顯示成功訊息
                setShowRemittanceModal(false);
                setNotificationMessage(response.data.message || '匯款後四碼已成功更新，訂單狀態已更新為待處理');
                setShowNotificationModal(true);
                setRemittanceCode(['', '', '', '']);
                
                // 重新獲取訂單列表
                await fetchOrders(username, searchParams, activeTab);
    
                // 更新訂單計數 - 移到這裡確保資料已更新
                setOrderCounts(prev => ({
                    ...prev,
                    '待回填後四碼': prev['待回填後四碼'] - 1,
                    '待處理': prev['待處理'] + 1
                }));
            } else {
                // 修改錯誤訊息的顯示方式
                setNotificationMessage(
                    `更新失敗: ${JSON.stringify(response.data)}`
                );
                setShowNotificationModal(true);
            }
        } catch (error) {
            console.error('錯誤詳情:', error);
            const errorMessage = error.response?.data?.message || 
                               error.message || 
                               '系統錯誤，請稍後再試';
            setNotificationMessage(`系統錯誤: ${errorMessage}`);
            setShowNotificationModal(true);
        }
    };

    // 處理貼上功能
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 4);
        const newCode = [...remittanceCode];
        [...pastedData].forEach((char, index) => {
            if (index < 4) newCode[index] = char;
        });
        setRemittanceCode(newCode);
    };

    // 處理按鍵事件（支援退格鍵）
    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && remittanceCode[index] === '' && index > 0) {
            const prevInput = document.getElementById(`digit-${index - 1}`);
            if (prevInput) {
                prevInput.focus();
                const newCode = [...remittanceCode];
                newCode[index - 1] = '';
                setRemittanceCode(newCode);
            }
        }
    };

    const fetchOrders = (username, params, status) => {
        axios.get(`${baseURL}/cart/byuser/`, { params: { ...params, username } })
            .then(response => {
                const filteredOrders = response.data.filter(order => order.status === status);
                setOrders(Array.isArray(filteredOrders) ? filteredOrders : []);
                updateOrderCounts(response.data);
                fetchDiscountData(filteredOrders);
            })
            .catch(error => {
                console.error("There was an error fetching the orders!", error);
                setOrders([]);
            });
    };

    const fetchDiscountData = (orders) => {
        orders.forEach(order => {
            axios.post(`${baseURL}/discount/getDiscountByOrder`, { order_id: order.order_id })
                .then(response => {
                    if (response.data.status === 1) {
                        setDiscountData(prevData => ({
                            ...prevData,
                            [order.order_id]: response.data.discounts
                        }));
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error("There was an error fetching the discount data!", error);
                });
        });
    };

    const fetchShippingCostData = async () => {
        try {
            const response = await axios.get(`${baseURL}/shippingCostSettings`);
            setShippingCostData(response.data);
        } catch (error) {
            console.error("Error fetching shipping cost settings:", error);
        }
    };

    const fetchRemittanceInfo = async () => {
        try {
            const response = await axios.get(`${baseURL}/set_RemittanceManager`);
            const data = response.data;
            const userRemittance = data.find(item => item.seller_id === username) || data.find(item => item.seller_id === '0');
            setRemittanceInfo(userRemittance);
        } catch (error) {
            console.error('Error fetching remittance info:', error);
        }
    };

    const updateOrderCounts = (orders) => {
        const counts = {
            待處理: 0,
            待回填後四碼: 0,
            待付款: 0,
            待出貨: 0,
            待收貨: 0,
            訂單已完成: 0,
            待換貨: 0 // 新增狀態
        };
        const seenOrderIds = new Set();
        orders.forEach(order => {
            if (!seenOrderIds.has(order.order_id) && counts[order.status] !== undefined) {
                counts[order.status]++;
                seenOrderIds.add(order.order_id);
            }
        });
        setOrderCounts(counts);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams({
            ...searchParams,
            [name]: value
        });
    };

    const handleSearch = () => {
        fetchOrders(username, searchParams, activeTab);
    };

    const toggleSortOrder = () => {
        setSortOrder(prevSortOrder => (prevSortOrder === 'desc' ? 'asc' : 'desc'));
    };

    const calculateTotal = (orders) => {
        return orders.reduce((total, order) => total + parseFloat(order.小計 || 0), 0);
    };

    const calculateOrderTotal = (orderItems) => {
        return orderItems.reduce((total, item) => total + parseFloat(item.小計 || 0), 0);
    };

    const calculateShippingCost = (totalAfterDiscount, address) => {
        let shippingCost = 0;
        let outlyingShippingCost = 0;

        for (const setting of shippingCostData) {
            if (totalAfterDiscount >= setting.shipping_cost_start && totalAfterDiscount <= setting.shipping_cost_end) {
                shippingCost = parseFloat(setting.shipping_cost);
                break;
            }
        }

        if (address.includes("金門縣") || address.includes("連江縣") || address.includes("澎湖縣")) {
            const outlyingShipping = shippingCostData.find(item => item.shipping_name === "離島運費");
            if (outlyingShipping) {
                outlyingShippingCost = parseFloat(outlyingShipping.shipping_cost);
            }
        }

        return { shippingCost, outlyingShippingCost };
    };

    const groupOrdersByOrderId = (orders) => {
        return orders.reduce((groups, order) => {
            const group = groups[order.order_id] || [];
            group.push(order);
            groups[order.order_id] = group;
            return groups;
        }, {});
    };

    const groupedOrders = groupOrdersByOrderId(orders);

    const tabs = ['待付款', '待回填後四碼', '待處理', '待出貨', '待收貨', '訂單已完成', '待換貨'];

    const handleShowMessageModal = (orderId) => {
        setSelectedOrderId(orderId);
        setShowMessageModal(true);
    };

    const handleCloseMessageModal = () => {
        setShowMessageModal(false);
        setSelectedOrderId(null);
    };

    const handleShowDeliveredModal = (orderId) => {
        setSelectedDeliveredOrderId(orderId);
        setShowDeliveredModal(true);
    };

    const handleCloseDeliveredModal = () => {
        setShowDeliveredModal(false);
        setSelectedDeliveredOrderId(null);
        setPicture(null);
    };

    const handleShowPictureModal = (orderId) => {
        axios.get(`${baseURL}/order_delivered/picture?order_id=${orderId}`)
            .then(response => {
                if (response.data.picture) {
                    setPicture(response.data.picture);
                    setShowPictureModal(true);
                } else {
                    alert("沒有上傳的收貨照片。");
                }
            })
            .catch(error => {
                console.error("There was an error fetching the delivery picture!", error);
            });
    };

    const handleClosePictureModal = () => {
        setShowPictureModal(false);
        setPicture(null);
    };

    const handlePictureChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setPicture(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleDeliveredSubmit = () => {
        console.log("handleDeliveredSubmit called");
        const formData = new FormData();
        formData.append('order_id', selectedDeliveredOrderId);
        formData.append('username', username);
        if (picture) {
            formData.append('picture', picture);
        }

        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        axios.post(`${baseURL}/order_delivered/insert`, formData)
            .then(() => {
                console.log("Request successful");
                setNotificationMessage("圖片新增成功.");
                setShowNotificationModal(true);
                handleCloseDeliveredModal();
                fetchOrders(username, searchParams, activeTab);
            })
            .catch(error => {
                console.error("There was an error saving the delivery confirmation!", error);
                setNotificationMessage("圖片新增發生錯誤,請稍後在試.");
                setShowNotificationModal(true);
            });
    };

    const handleUpdateDeliveredSubmit = () => {
        console.log("handleUpdateDeliveredSubmit called");
        const formData = new FormData();
        formData.append('order_id', selectedDeliveredOrderId);
        if (picture) {
            formData.append('picture', picture);
        }

        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        axios.post(`${baseURL}/order_delivered/update`, formData)
            .then(() => {
                console.log("Update request successful");
                setNotificationMessage("圖片更新成功.");
                setShowNotificationModal(true);
                handleCloseDeliveredModal();
                fetchOrders(username, searchParams, activeTab);
            })
            .catch(error => {
                console.error("There was an error updating the delivery confirmation!", error);
                setNotificationMessage("圖片更新發生錯誤,請稍後在試.");
                setShowNotificationModal(true);
            });
    };

    const handleCloseNotificationModal = () => {
        setShowNotificationModal(false);
        setNotificationMessage('');
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.children}
        </Tooltip>
    );

    return (
        <>
            <header className="bg-success py-5 mb-5">
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder" style={{ color: 'white' }}>我的訂單</h1>
                        <div className="p-2">My Orders</div>
                    </div>
                </div>
            </header>

            <div className="check-orders container">
                <div className="tabs">
                    {tabs.map(tab => (
                        <button
                            key={tab}
                            className={activeTab === tab ? 'active' : ''}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                            <span className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${orderCounts[tab] !== 0 ? 'bg-danger' : 'bg-secondary'}`}>
                                {orderCounts[tab]}
                                <span className="visually-hidden">unread messages</span>
                            </span>
                        </button>
                    ))}
                </div>

                <div className="search-bar">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" name="order_id" placeholder="訂單號碼" value={searchParams.order_id} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="recipient_name" placeholder="收件人姓名" value={searchParams.recipient_name} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="address" placeholder="收件人地址" value={searchParams.address} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="mobile" placeholder="收件人電話" value={searchParams.mobile} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="email" placeholder="收件人信箱" value={searchParams.email} onChange={handleInputChange} />
                        <button className="btn btn-primary" onClick={handleSearch}>搜尋</button>
                        <button onClick={toggleSortOrder} className="btn btn-secondary">
                            {sortOrder === 'desc' ? <i className='bx bx-down-arrow-alt'></i> : <i className='bx bx-up-arrow-alt'></i>}
                        </button>
                    </div>
                </div>

                {(activeTab === '待付款' || activeTab === '待回填後四碼') && remittanceInfo && (
                    <div className="search-bar">
                        <fieldset className="form-group border p-4 border border-secondary shadow p-3 mb-5 bg-white rounded w-100">
                            <legend className="border-bottom mb-4 pb-2">匯款資訊</legend>
                            <div className="form-check">
                                <label className="text-secondary">銀行 : </label>
                                <label className="text-primary text-monospace">
                                    <h3>{remittanceInfo.bank_name}</h3>
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="text-secondary">戶名 : </label>
                                <label className="text-primary text-monospace">
                                    <h3>{remittanceInfo.account_name}</h3>
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="text-secondary">代號 : </label>
                                <label className="text-primary text-monospace">
                                    <h3>{remittanceInfo.bank_code}</h3>
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="text-secondary">帳號 : </label>
                                <label className="text-primary text-monospace">
                                    <h3>{remittanceInfo.account_number}</h3>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                )}

                <div className="order-list">
                    {Object.keys(groupedOrders).length > 0 ? (
                        Object.keys(groupedOrders).sort((a, b) => {
                            if (sortOrder === 'desc') {
                                return b - a;
                            } else {
                                return a - b;
                            }
                        }).map(orderId => {
                            const initialTotal = calculateOrderTotal(groupedOrders[orderId]);
                            let totalAfterDiscount = initialTotal;
                            let totalDiscount = 0;

                            if (discountData[orderId] && discountData[orderId].length > 0) {
                                const discountNames = discountData[orderId][0].discount_name.split(',');
                                const discountRatesAndAmounts = discountData[orderId][0].discount_rateAndamount.split(',');

                                discountNames.forEach((name, index) => {
                                    const discountValue = discountRatesAndAmounts[index];
                                    const isPercentage = discountValue.includes('%');
                                    let discountAmount;

                                    if (isPercentage) {
                                        const percentage = parseFloat(discountValue) / 100;
                                        discountAmount = totalAfterDiscount * percentage;
                                    } else {
                                        discountAmount = parseFloat(discountValue);
                                    }

                                    totalAfterDiscount -= discountAmount;
                                    totalDiscount += discountAmount;
                                });
                            }

                            const { shippingCost, outlyingShippingCost } = calculateShippingCost(totalAfterDiscount, groupedOrders[orderId][0].address_line1);
                            const totalShippingCost = shippingCost + outlyingShippingCost;
                            const finalAmount = Math.round(totalAfterDiscount + totalShippingCost);

                            return (
                                <div className="order-block p-3" key={orderId}>
                                    <div className="order-header highlighted p-3 d-flex justify-content-between align-items-center">
                                        <span>訂單號碼: {orderId}</span>
                                        <div className="d-flex align-items-center">
                                            {activeTab === '訂單已完成' && (
                                                <>
                                                    <i className='bx bx-upload' onClick={(e) => { e.stopPropagation(); handleShowDeliveredModal(orderId); }} style={{ fontSize: '25px', cursor: 'pointer', marginRight: '10px' }} title="補傳已到貨圖片"></i>
                                                    <i className='bx bx-image' onClick={(e) => { e.stopPropagation(); handleShowPictureModal(orderId); }} style={{ fontSize: '25px', cursor: 'pointer', marginRight: '10px' }} title="查看收貨照片"></i>
                                                </>
                                            )}
                                            <i className='bx bx-message-rounded-dots' onClick={() => handleShowMessageModal(orderId)} style={{ fontSize: '25px', cursor: 'pointer' }} title="留言"></i>
                                        </div>
                                    </div>
                                    <div className="order-recipient p-3">
                                    <div>收件人姓名: {groupedOrders[orderId][0].recipient_name}</div>
                                    <div>收件人電話: {groupedOrders[orderId][0].mobile}</div>
                                    <div>收件人信箱: {groupedOrders[orderId][0].email}</div>
                                    <div>收件人地址: {groupedOrders[orderId][0].address_line1}</div>
                                    <div>運送方式: {groupedOrders[orderId][0].address_line1 ? "自家住址" : "便利商店"}</div>
                                    <div>付款方式: {
                                        groupedOrders[orderId][0].payment_method === '匯款'
                                        ? `${groupedOrders[orderId][0].payment_method} (後四碼: ${groupedOrders[orderId][0].remittance_code})`
                                        : groupedOrders[orderId][0].payment_method
                                    }</div>
                                    </div>
                                    {groupedOrders[orderId].map(order => (
                                        <div className="order-item" key={order.id}>
                                            <img src={process.env.PUBLIC_URL + '/img/' + order.img} alt={order.商品名稱} className="product-img" />
                                            <div className="order-info">
                                                <div className="product-name">{order.商品名稱}  {order.顏色種類 && (<><span className="left-align-color"> {order.顏色種類}</span></>)} - {order.包片數 && (<><span className="left-align-color"> {order.包片數}</span></>)}</div>
                                                <div className="product-details">
                                                    <span className="left-align">
                                                    {order.度數 && (<>度數: <span className="left-align-amount">{order.度數}</span><br /></>)}
                                                    {order.左眼度數 && (<> 左眼度數: <span className="left-align-amount">{order.左眼度數}</span><br /></>)}
                                                    {order.右眼度數 && (<> 右眼度數: <span className="left-align-amount">{order.右眼度數}</span><br /></>)}
                                                    {order.角度 && (<> 角度: <span className="left-align-amount">{order.角度}</span><br /></>)}
                                                    {order.散光 && (<> 散光: <span className="left-align-color">{order.散光}</span><br /></>)}
                                                    {order.老花 && (<> 老花: <span className="left-align-amount">{order.老花}</span></>)}
                                                      </span>
                                                    <span className="right-align">${order.售價} x{order.數量}</span>
                                                </div>
                                                <div className="subtotal right-align">${order.小計}</div>
                                            </div>
                                        </div>
                                    ))}
                                    <hr />
                                    <div className="order-discount right-align">
                                    {discountData[orderId] && discountData[orderId].length > 0 && (
                                        <>
                                            <div>商品總金額: <span className="order-total-amount">${initialTotal.toFixed(2)}</span></div>
                                            {/* {discountData[orderId][0].discount_name.split(',').map((name, index) => (
                                                <div key={index}>折扣{index + 1}({name}): {discountData[orderId][0].discount_rateAndamount.split(',')[index]}</div>
                                            ))} */}
                                            <div className="order-discount-b right-align">
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            {discountData[orderId][0].discount_name.split(',').map((name, index) => (
                                                                <div key={index}>折扣{index + 1}({name}): {discountData[orderId][0].discount_rateAndamount.split(',')[index]}</div>
                                                            ))}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="bx bx-info-circle me-1" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                                </OverlayTrigger>
                                                折扣總金額: <span className="order-total-amount">${Math.round(totalDiscount)}</span>
                                            </div>
                                        </>
                                    )}
                                    </div>
                                    <div className="order-shipping right-align">
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="button-tooltip">
                                                    <div>基本運費: {shippingCost.toFixed(2)}</div>
                                                    {outlyingShippingCost > 0 && (
                                                        <div>離島運費: {outlyingShippingCost.toFixed(2)}</div>
                                                    )}
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bx bx-info-circle me-1" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                        </OverlayTrigger>
                                        運費總金額: <span className="order-total-amount">${(shippingCost + outlyingShippingCost).toFixed(2)}</span>
                                    </div>
                                    <div className="order-final right-align">
                                        總付款金額: <span className="order-total-amount">${finalAmount}</span>
                                    </div>
                                    {activeTab === '待收貨' && (
                                        <div style={{ width: '100%' }}>
                                            <Button variant="danger" style={{ width: '100%' }} onClick={() => handleShowDeliveredModal(orderId)}>已到貨</Button>
                                        </div>
                                    )}
                                    {activeTab === '待回填後四碼' && (
                                        <>
                                            <div className="alert alert-info d-flex justify-content-center align-items-center">
                                                <span className="caret"></span> 請填寫匯款後四碼，以便我們盡快為您處理訂單 <span className="caret"></span>
                                            </div>
                                            <Button 
                                                variant="primary" 
                                                style={{ width: '100%' }} 
                                                onClick={() => {
                                                    setSelectedOrderId(orderId);
                                                    setShowRemittanceModal(true);
                                                }}
                                            >
                                                回報匯款後四碼
                                            </Button>
                                        </>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-orders">沒有找到符合條件的訂單</div>
                    )}
                </div>
            </div>

            {/* 匯款後四碼回報 Modal */}
            <Modal show={showRemittanceModal} onHide={() => setShowRemittanceModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>回報匯款後四碼</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="d-block text-center">請輸入匯款帳號後四碼</Form.Label>
                            <div className="d-flex gap-2 justify-content-center">
                                {[0, 1, 2, 3].map((index) => (
                                    <Form.Control
                                        key={index}
                                        id={`digit-${index}`}
                                        type="text"
                                        maxLength="1"
                                        value={remittanceCode[index]}
                                        onChange={(e) => handleDigitChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        onPaste={handlePaste}
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            textAlign: 'center',
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold'
                                        }}
                                    />
                                ))}
                            </div>
                            <Form.Text className="text-muted text-center d-block mt-2">
                                請輸入您匯款時使用的帳號後四碼，我們將盡快為您處理訂單
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowRemittanceModal(false);
                        setRemittanceCode(['', '', '', '']);
                    }}>
                        取消
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={() => {
                            const code = remittanceCode.join('');
                            if (code.length === 4) {
                                handleRemittanceCodeSubmit(code);
                            } else {
                                setNotificationMessage('請輸入完整的 4 位數字');
                                setShowNotificationModal(true);
                            }
                        }}
                    >
                        確認送出
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeliveredModal} onHide={handleCloseDeliveredModal}>
                <Modal.Header closeButton>
                    <Modal.Title>確認已到貨</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>上傳收貨照片 (可選)</Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={handlePictureChange} />
                            <Form.Text className="text-muted">
                                請上傳您已收到貨品的照片，以便我們進行驗證。此步驟是可選的，但有助於加快處理流程。
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeliveredModal}>
                        取消
                    </Button>
                    {activeTab === '待收貨' ? (
                        <Button variant="primary" onClick={handleDeliveredSubmit}>
                            送出
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={handleUpdateDeliveredSubmit}>
                            更新
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal show={showPictureModal} onHide={handleClosePictureModal}>
                <Modal.Header closeButton>
                    <Modal.Title>收貨照片</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {picture ? (
                        <img src={picture} alt="收貨照片" style={{ width: '100%' }} />
                    ) : (
                        <p>沒有上傳的收貨照片。</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePictureModal}>
                        關閉
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNotificationModal} onHide={handleCloseNotificationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>通知</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{notificationMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNotificationModal}>
                        關閉
                    </Button>
                </Modal.Footer>
            </Modal>

            {selectedOrderId && (
                <OrderMessage
                    orderId={selectedOrderId}
                    show={showMessageModal}
                    handleClose={handleCloseMessageModal}
                />
            )}
        </>
    );
};

export default CheckOrders;
