import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Stack, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CartContext } from './CartContext.js';
import baseURL from "./manager/apiConfig.js";
import styles from './css/CheckShippingInfo.module.css';

const CheckShippingInfo = () => {
    const [user, setUser] = useState({
        username: localStorage.getItem('username'),
    });
    const { cartItems, setCartItems } = useContext(CartContext);
    const [profile, setProfile] = useState({
        recipient_name: '',
        email: '',
        mobile: '',
        postal_code: '',
        address_line1: '',
        address_line2: '',
        notes: ''
    });
    const [paymentMethod, setPaymentMethod] = useState('bankTransfer');
    const [shippingMethod, setShippingMethod] = useState('home');
    const [storeType, setStoreType] = useState('');
    const [storeAddress, setStoreAddress] = useState('');
    const [remittanceInfo, setRemittanceInfo] = useState(null);
    const [discounts, setDiscounts] = useState([]);
    const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
    const [discountedTotal, setDiscountedTotal] = useState(0);
    const [shippingCost, setShippingCost] = useState(0);
    const [outlyingShippingCost, setOutlyingShippingCost] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const navigate = useNavigate();

    const grandTotal = cartItems.reduce((total, product) => {
        return total + (product.福利價 !== "0" ? product.福利價 * product.quantity : product.售價 * product.quantity);
    }, 0);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${baseURL}/userProfile/getProfile`, { 
                    params: { username: user.username } 
                });
                const data = response.data;
                setProfile({
                    recipient_name: data[0].recipient_name || '',
                    email: data[0].email || '',
                    mobile: data[0].mobile[0] || '',
                    postal_code: data[0].postal_code || '',
                    address_line1: data[0].address_line1 || '',
                    address_line2: data[0].address_line2 || '',
                    notes: data[0].notes || ''
                });
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        const fetchRemittanceInfo = async () => {
            try {
                const response = await axios.get(`${baseURL}/set_RemittanceManager`);
                const data = response.data;
                const userRemittance = data.find(item => item.seller_id === user.username) || data.find(item => item.seller_id === '0');
                setRemittanceInfo(userRemittance);
            } catch (error) {
                console.error('Error fetching remittance info:', error);
            }
        };

        const fetchDiscounts = async () => {
            const username = localStorage.getItem('username');
            if (username) {
                try {
                    const response = await axios.post(`${baseURL}/discount/getDiscountbyuser`, { username });
                    if (response.data.status === 1) {
                        const discounts = response.data.discounts;
                        setDiscounts(discounts);
                    }
                } catch (error) {
                    console.error('Error fetching discounts:', error);
                }
            }
        };

        fetchProfile();
        fetchRemittanceInfo();
        fetchDiscounts();
    }, [user.username]);

    useEffect(() => {
        calculateDiscountAndShipping();
    }, [discounts, profile.address_line1]);

    const calculateDiscountAndShipping = () => {
        let runningTotal = grandTotal;
        let totalDiscount = 0;

        discounts.forEach(discount => {
            if (discount.discount_rate) {
                let discountAmount = (runningTotal * discount.discount_rate) / 100;
                if (!isNaN(discountAmount)) {
                    totalDiscount += discountAmount;
                    runningTotal -= discountAmount;
                }
            } else if (discount.discount_amount) {
                let discountAmount = parseFloat(discount.discount_amount);
                if (!isNaN(discountAmount)) {
                    totalDiscount += discountAmount;
                    runningTotal -= discountAmount;
                }
            }
        });

        const absTotalDiscount = Math.abs(totalDiscount);
        setTotalDiscountAmount(absTotalDiscount);
        setDiscountedTotal(runningTotal);

        calculateShippingCost(runningTotal, profile.address_line1);
    };

    const calculateShippingCost = async (total, address) => {
        try {
            const response = await axios.get(`${baseURL}/shippingCostSettings`);
            const shippingCostData = response.data;
            let shippingCost = 0;
            let outlyingShippingCost = 0;

            for (const setting of shippingCostData) {
                if (total >= setting.shipping_cost_start && total <= setting.shipping_cost_end) {
                    shippingCost = parseFloat(setting.shipping_cost);
                    break;
                }
            }

            if (address.includes("金門縣") || address.includes("連江縣") || address.includes("澎湖縣")) {
                const outlyingShipping = shippingCostData.find(item => item.shipping_name === "離島運費");
                if (outlyingShipping) {
                    outlyingShippingCost = parseFloat(outlyingShipping.shipping_cost);
                }
            }

            setShippingCost(shippingCost);
            setOutlyingShippingCost(outlyingShippingCost);

            const finalTotal = Math.round(total + shippingCost + outlyingShippingCost);
            setFinalAmount(finalTotal);
        } catch (error) {
            console.error('Error fetching shipping cost settings:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));

        // 在每次更改地址時重新計算運費
        if (name === 'address_line1') {
            calculateDiscountAndShipping();
        }
    };

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleShippingChange = (e) => {
        setShippingMethod(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const orderData = {
                cartItems: cartItems.map(item => ({
                    ...item,
                    度數: item.度數 || '',
                    散光: item.散光 || '',
                    角度: item.角度 || '',
                    老花: item.老花 || '',
                    顏色種類: item.顏色種類 || ''
                })),
                username: user.username,
                ...profile,
                paymentMethod: paymentMethod === 'bankTransfer' ? '待回填後四碼' : '待付款', // 修改這裡
                shippingMethod,
                storeType: shippingMethod === 'store' ? storeType : '',
                storeAddress: shippingMethod === 'store' ? storeAddress : '',
                final_total: finalAmount
            };

            if (shippingMethod === 'store') {
                orderData.postal_code = '';
                orderData.address_line1 = '';
                orderData.address_line2 = '';
            }

            const response = await axios.post(`${baseURL}/cart/addOrderWithShipping`, orderData);
            
            if (response.data.status === 1) {
                alert(response.data.order_id + ' 訂單已成立');
                setCartItems([]);
                localStorage.removeItem(`${user.username}_cartItems`);
                navigate('/');
            } else {
                alert('Failed to place order');
                console.error('Failed to place order:', response.data);
            }
        } catch (error) {
            console.error('Error submitting order:', error);
            alert('Error submitting order');
        }
    };

    return (
        <>
            <header className="bg-success py-5 mb-5">
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white"> 
                        <h1 className="display-4 fw-bolder" style={{ color: 'white' }}>購物車</h1>
                        <ProgressBar variant="warning" now={100} />
                        <Stack direction="horizontal" gap={3}>
                            <div
                                className="p-2 ms-auto"
                                onClick={() => navigate('/checkout')}
                                style={{ cursor: 'pointer', color: 'white' }}
                                onMouseEnter={(e) => e.target.style.color = 'yellow'}
                                onMouseLeave={(e) => e.target.style.color = 'white'}
                            >
                                1.確認商品
                            </div>
                            <div className="vr" />
                            <div className="p-2">2.確認送貨資訊</div>
                        </Stack>        
                    </div>
                </div>
            </header>
            <Container className={`${styles.container} mb-5`}>
                <Row>
                    <Col md={6}>
                        <h4>付款方式</h4>
                        <Card className="shadow-sm p-3 mb-2 bg-white rounded">
                            <Card.Body>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="radio"
                                        label="匯款"
                                        name="paymentMethod"
                                        value="bankTransfer"
                                        onChange={handlePaymentChange}
                                        checked={paymentMethod === 'bankTransfer'}
                                        required
                                    />
                                    {paymentMethod === 'bankTransfer' && remittanceInfo && (
                                        <fieldset className="form-group border p-3">
                                            <div className="form-check">
                                                <label className="text-secondary">銀行 : </label>
                                                <label className="text-primary text-monospace">
                                                    <h3>{remittanceInfo.bank_name}</h3>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="text-secondary">戶名 : </label>
                                                <label className="text-primary text-monospace">
                                                    <h3>{remittanceInfo.account_name}</h3>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="text-secondary">代號 : </label>
                                                <label className="text-primary text-monospace">
                                                    <h3>{remittanceInfo.bank_code}</h3>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="text-secondary">帳號 : </label>
                                                <label className="text-primary text-monospace">
                                                    <h3>{remittanceInfo.account_number}</h3>
                                                </label>
                                            </div>
                                        </fieldset>
                                    )}
                                    <Form.Check
                                        type="radio"
                                        label="貨到付款"
                                        name="paymentMethod"
                                        value="COD"
                                        onChange={handlePaymentChange}
                                        checked={paymentMethod === 'COD'}
                                        required
                                    />
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <Card className="shadow-sm p-3 mb-2 bg-light rounded">
                            <Card.Body>
                                <h5 className="text-center">訂單摘要</h5>
                                <div className="d-flex justify-content-between">
                                    <span>商品總金額:</span>
                                    <span className="text-primary">{grandTotal.toFixed(2)}元</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    
                                    <span>折扣總金額:<OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                {discounts.map((discount, index) => (
                                                    <div key={index}>
                                                        {discount.discount_rate ? (
                                                            <div>折扣{index + 1} ({discount.discount_name}): {discount.discount_rate}%</div>
                                                        ) : (
                                                            <div>折扣{index + 1} ({discount.discount_name}): {discount.discount_amount}元</div>
                                                        )}
                                                    </div>
                                                ))}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="bx bx-info-circle me-1" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                    </OverlayTrigger></span>
                                    <span className="text-primary">{Math.round(totalDiscountAmount)}元</span>
                                </div>
                                {/* <div className="d-flex justify-content-between">
                                    <span>折扣後總金額:</span>
                                    <span className="text-primary">{discountedTotal.toFixed(0)}元</span>
                                </div> */}
                                <div className="d-flex justify-content-between">
                                    
                                    <span>運費總金額:<OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                <div>基本運費: {shippingCost.toFixed(2)}元</div>
                                                {outlyingShippingCost > 0 && (
                                                    <div>離島運費: {outlyingShippingCost.toFixed(2)}元</div>
                                                )}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="bx bx-info-circle me-1" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                    </OverlayTrigger></span>
                                    <span className="text-primary">{(shippingCost + outlyingShippingCost).toFixed(2)}元</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>總付款金額:</span>
                                    <span className="text-primary">{finalAmount}元</span>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <h4>配送資訊</h4>
                        <Card className="shadow-sm p-3 mb-5 bg-white rounded">
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Group controlId="formRecipientName">
                                                <Form.Label>收件人 <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="recipient_name"
                                                    value={profile.recipient_name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formEmail">
                                                <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={profile.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Group controlId="formMobile">
                                                <Form.Label>手機 <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    name="mobile"
                                                    value={profile.mobile}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group className="mb-3">
                                        <Form.Label>運送方式 <span className="text-danger">*</span></Form.Label>
                                        <div>
                                            <Form.Check
                                                type="radio"
                                                label="宅配地址"
                                                name="shippingMethod"
                                                value="home"
                                                onChange={handleShippingChange}
                                                checked={shippingMethod === 'home'}
                                                required
                                            />
                                            {/* <Form.Check
                                                type="radio"
                                                label="便利商店"
                                                name="shippingMethod"
                                                value="store"
                                                onChange={handleShippingChange}
                                                checked={shippingMethod === 'store'}
                                                required
                                            /> */}
                                        </div>
                                    </Form.Group>

                                    {shippingMethod === 'home' && (
                                        <>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Group controlId="formPostalCode">
                                                        <Form.Label>郵遞區號 <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="postal_code"
                                                            value={profile.postal_code}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="formAddressLine1">
                                                        <Form.Label>地址 <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="address_line1"
                                                            value={profile.address_line1}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                    {shippingMethod === 'store' && (
                                        <>
                                            <Form.Group controlId="formStoreType" className="mb-3">
                                                <Form.Label>便利商店種類 <span className="text-danger">*</span></Form.Label>
                                                <Form.Select
                                                    value={storeType}
                                                    onChange={(e) => setStoreType(e.target.value)}
                                                    required
                                                >
                                                    <option value="">選擇便利商店種類</option>
                                                    <option value="FamilyMart">全家</option>
                                                    <option value="7-11">7-11</option>
                                                    <option value="HiLife">萊爾富</option>
                                                    <option value="OKMart">OK</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group controlId="formStoreAddress" className="mb-3">
                                                <Form.Label>地址與店名 <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={storeAddress}
                                                    onChange={(e) => setStoreAddress(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </>
                                    )}

                                    <Form.Group controlId="formNotes" className="mb-3">
                                        <Form.Label>備註</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="notes"
                                            value={profile.notes}
                                            onChange={handleChange}
                                            rows={3}
                                        />
                                    </Form.Group>

                                    <Button variant="primary" type="submit" className="w-100">
                                        成立訂單
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CheckShippingInfo;